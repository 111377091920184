import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => createStyles({}));

type Props = {
  default: any;
};

export default function NotFound(props: Props) {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Not found
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          <p>Sorry, we couldn't find the page you're looking for.</p>
          <p>
            Please check that you are logged in and have permissions to access
            this page.
          </p>
        </Typography>
      </Box>
    </Container>
  );
}
